import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LabWeb from './content/labweb';
import Footer from './footer/footer';
import reportWebVitals from './reportWebVitals';
import {Route, BrowserRouter as Router}from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import {
  createHashRouter,
  RouterProvider
} from 'react-router-dom';
const router = createHashRouter([
  {
    path: "/*",
    element: <LabWeb />,
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();